import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_SKU } from '../../../constants/pagination';
import { ADDITIONAL_INFO, ADDITIONAL_INFO_SUBMITTED, IN_REVIEW, REQUESTED } from '../../../constants/sku';

const skuUrl = 'api/admin/sku';
const questionUrl = 'api/admin/question';
const customerSkuUrl = 'api/admin/customer-sku';

export const fetchSkus = createAsyncThunk(
  'skus/fetchSkus',
  async ({ customer_id = '', page = 1, code = '', filter_status = '', perPage = PER_PAGE_SKU }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/listingSku`, {
        params: { page, per_page: perPage, reference_number: code, customer_id, status: filter_status },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching questionnaire
export const fetchQuestionnaire = createAsyncThunk('skus/fetchQuestionnaire', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${skuUrl}/showingSku/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for fetching logs
export const fetchLogs = createAsyncThunk('skus/fetchLogs', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${customerSkuUrl}/log/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const setSkuInReview = createAsyncThunk(
  'skus/setSkuInReview',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/in-review/${id}`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const setSkuInAdditionalInfo = createAsyncThunk(
  'skus/setSkuInAdditionalInfo',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${questionUrl}/update-status/additional-info/${id}`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const setSkuInFinalized = createAsyncThunk(
  'skus/setSkuInFinalized',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/in-finalized/${id}`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const setSkuInCanceled = createAsyncThunk(
  'skus/setSkuInCanceled',
  async ({ id, data }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${skuUrl}/canceled/${id}`, data, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const pickSku = createAsyncThunk(
  'skus/pickSku',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/pick/${id}`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const leaveSku = createAsyncThunk(
  'skus/leaveSku',
  async ({ id, data }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${skuUrl}/leave/${id}`, data, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
export const countRequestedSku = createAsyncThunk(
  'skus/countRequestedSku',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/count-requested`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const SkuSlice = createSlice({
  name: 'sku',
  initialState: {
    loading: false,
    isModalLoading: false,
    isQuestionLoading: false,
    isLogLoading: false,
    isCountLoading: false,
    skus: [],
    questions: [],
    logs: [],
    sku: null,
    firstData: null,
    pageCount: 0,
    currentPage: 0,
    requestedCount: 0,
    code: '',
    filter_status: REQUESTED + ',' + IN_REVIEW + ',' + ADDITIONAL_INFO + ',' + ADDITIONAL_INFO_SUBMITTED,
    perPage: PER_PAGE_SKU,
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.code = action.payload;
      state.currentPage = 0;
    },
    setFilterStatus: (state, action) => {
      state.filter_status = action.payload;
      state.currentPage = 0;
    },
    setFirstData: (state, action) => {
      state.firstData = action.payload;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch skus
      .addCase(fetchSkus.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchSkus.fulfilled, (state, action) => {
        state.loading = false;
        state.skus = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchSkus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchQuestionnaire.pending, (state) => {
        state.isQuestionLoading = true;
        state.error = '';
      })
      .addCase(fetchQuestionnaire.fulfilled, (state, action) => {
        state.isQuestionLoading = false;
        let data = action.payload.payload.data
        state.questions = data;
        if (data[0]) {
          state.firstData = data[0];
        }
      })
      .addCase(fetchQuestionnaire.rejected, (state, action) => {
        state.isQuestionLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLogs.pending, (state) => {
        state.isLogLoading = true;
        state.error = '';
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.isLogLoading = false;
        state.logs = action.payload.payload;
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.isLogLoading = false;
        state.error = action.payload;
      })
      // set sku status to in review
      .addCase(setSkuInReview.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(setSkuInReview.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // set sku status to additional info
      .addCase(setSkuInAdditionalInfo.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(setSkuInAdditionalInfo.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInAdditionalInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // set sku status to approve
      .addCase(setSkuInFinalized.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(setSkuInFinalized.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInFinalized.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // set sku status to cancel
      .addCase(setSkuInCanceled.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(setSkuInCanceled.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInCanceled.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // pick sku
      .addCase(pickSku.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(pickSku.fulfilled, (state, action) => {
        state.isModalLoading = false;
        let data = action.payload.payload;
        const index = state.skus.findIndex(sku => sku.id === data.id);
        if (index !== -1) {
          state.skus[index] = data;
        }
      })
      .addCase(pickSku.rejected, (state, action) => {
        state.isModalLoading = false;
      })
      // leave sku
      .addCase(leaveSku.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(leaveSku.fulfilled, (state, action) => {
        state.isModalLoading = false;
        let data = action.payload.payload;
        const index = state.skus.findIndex(sku => sku.id === data.id);
        if (index !== -1) {
          state.skus[index] = data;
        }
      })
      .addCase(leaveSku.rejected, (state, action) => {
        state.isModalLoading = false;
      })
      // count requested sku
      .addCase(countRequestedSku.pending, (state) => {
        state.isCountLoading = true;
        state.error = '';
      })
      .addCase(countRequestedSku.fulfilled, (state, action) => {
        state.isCountLoading = false;
        state.requestedCount = action.payload.payload;
      })
      .addCase(countRequestedSku.rejected, (state, action) => {
        state.isCountLoading = false;
      });
  }
});

export const { setFilter, setFilterStatus, setFirstData, setPage, setPerPage, clearError, clearValidationError } = SkuSlice.actions;

export default SkuSlice.reducer;
