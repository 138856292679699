import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_COUPON } from '../../../constants/pagination';

const couponUrl = 'api/admin/discount-coupon';


// Async thunk for fetching coupons
export const fetchCoupons = createAsyncThunk(
  'coupons/fetchCoupons',
  async ({ page = 1, filter = '', perPage = PER_PAGE_COUPON }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${couponUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching coupon
export const fetchCoupon = createAsyncThunk('coupons/fetchCoupon', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${couponUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for creating coupon
export const createCoupon = createAsyncThunk(
  'coupons/createCoupon',
  async (newCoupon, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${couponUrl}/store`, newCoupon, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for auto generating coupon
export const autoGenerateCoupon = createAsyncThunk(
  'coupons/autoGenerateCoupon',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${couponUrl}/generate-coupon`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for updating coupon
export const updateCoupon = createAsyncThunk('coupons/updateCoupon', async (coupon, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    let couponData = state.coupon.coupon;
    const response = await axiosInstance.post(`${couponUrl}/update/${couponData.id}`, coupon, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for deleting coupon
export const deleteCoupon = createAsyncThunk('coupons/deleteCoupon', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.delete(`${couponUrl}/destroy/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const DiscountCoupounSlice = createSlice({
  name: 'discountCoupon',
  initialState: {
    loading: false,
    isModalLoading: false,
    coupons: [],
    coupon: null,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_COUPON,
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch coupons
      .addCase(fetchCoupons.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create coupon
      .addCase(createCoupon.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(createCoupon.fulfilled, (state, action) => {
        state.isModalLoading = false;
        state.coupons.push(action.payload.payload);
      })
      .addCase(createCoupon.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // auto generate coupon
      .addCase(autoGenerateCoupon.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(autoGenerateCoupon.fulfilled, (state) => {
        state.isModalLoading = false;
      })
      .addCase(autoGenerateCoupon.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // Fetch coupon
      .addCase(fetchCoupon.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchCoupon.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.coupon = data;
        state.isModalLoading = false;
      })
      .addCase(fetchCoupon.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // update coupon
      .addCase(updateCoupon.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.coupons.findIndex(coupon => coupon.id === data.id);
        if (index !== -1) {
          state.coupons[index] = data;
        }
        state.coupon = data;
        state.isModalLoading = false;
      })
      .addCase(updateCoupon.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // delete coupon
      .addCase(deleteCoupon.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        state.coupon = null;
        state.isModalLoading = false;
      })
      .addCase(deleteCoupon.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      });
  }
})


export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = DiscountCoupounSlice.actions;

export default DiscountCoupounSlice.reducer;