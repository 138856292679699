import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_AUDIT_LOG } from '../../../constants/pagination';

const auditLogUrl = 'api/admin/audit-logs';

export const fetchAuditLogs = createAsyncThunk(
    'auditLogs/fetchAuditLogs',
    async ({ page = 1, filter = '', perPage = PER_PAGE_AUDIT_LOG }, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const response = await axiosInstance.get(`${auditLogUrl}`, {
                params: { page, per_page: perPage, search: filter },
                headers: prepareHeaders(state)
            });
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue);
        }
    }
);

const AuditLogSlice = createSlice({
    name: 'auditLog',
    initialState: {
        loading: false,
        auditLogs: [],
        auditLog: null,
        pageCount: 0,
        currentPage: 0,
        filter: '',
        perPage: PER_PAGE_AUDIT_LOG,
        error: ''
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
            state.currentPage = 0;
        },
        setPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setPerPage: (state, action) => {
            state.perPage = action.payload;
            state.currentPage = 0;
        },
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers: (builder) => {
        builder
            // fetch auditLogs
            .addCase(fetchAuditLogs.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchAuditLogs.fulfilled, (state, action) => {
                state.loading = false;
                state.auditLogs = action.payload.payload.data;
                state.pageCount = action.payload.payload.last_page;
                state.currentPage = action.payload.payload.current_page - 1;
            })
            .addCase(fetchAuditLogs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { setFilter, setPage, setPerPage, clearError } = AuditLogSlice.actions;

export default AuditLogSlice.reducer;
