import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { PER_PAGE_CUSTOMER_SKU } from "../../../constants/pagination";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { handleApiError } from "../../common/utils/ErrorHandler";
import axiosInstance from "../../../app/axiosInstance";

const salesListUrl = 'api/admin/customer-sku';

// Async thunk for fetching salesList
export const fetchSalesList = createAsyncThunk(
  'salesList/fetchSalesList',
  async ({ page = 1, filter = '', perPage = PER_PAGE_CUSTOMER_SKU }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${salesListUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Async thunk for fetching Sales Data
export const fetchSalesData = createAsyncThunk('salesList/fetchSalesData', async (id, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${salesListUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue);
  }
});

export const importSalesData = createAsyncThunk(
  'salesList/importSalesData',
  async (csvData, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const formData = new FormData();
      formData.append('csv_file', csvData);

      const response = await axiosInstance.post(`api/admin/sku/process-csv-file`, formData, {
        headers: prepareHeaders(state, true, {
          'Content-Type': 'multipart/form-data',
        }),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, true);
    }
  }
);


const SalesListSlice = createSlice({
  name: 'sales',
  initialState: {
    loading: false,
    isModalLoading: false,
    salesLists: [],
    salesList: null,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_CUSTOMER_SKU,
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch sales list
      .addCase(fetchSalesList.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchSalesList.fulfilled, (state, action) => {
        state.loading = false;
        state.salesLists = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchSalesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch sales data
      .addCase(fetchSalesData.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchSalesData.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.salesList = data;
        state.isModalLoading = false;
      })
      .addCase(fetchSalesData.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // import sales csv data
      .addCase(importSalesData.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(importSalesData.fulfilled, (state, action) => {
        state.isModalLoading = false;
      })
      .addCase(importSalesData.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload === 'object') {
          state.validationError = action.payload.errors;
        }
      });
  }
})

export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = SalesListSlice.actions;

export default SalesListSlice.reducer;