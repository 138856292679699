import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_PRICE_LIST } from '../../../constants/pagination';

const priceListUrl = 'api/admin/price-list';


// Async thunk for fetching priceLists
export const fetchPriceLists = createAsyncThunk(
  'priceLists/fetchPriceLists',
  async ({ page = 1, filter = '', perPage = PER_PAGE_PRICE_LIST }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${priceListUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching priceLists
export const fetchPriceList = createAsyncThunk('priceLists/fetchPriceList', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${priceListUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const fetchPriceListData = createAsyncThunk(
  'priceLists/fetchPriceListData',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`api/price-list`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for creating priceLists
export const createPriceList = createAsyncThunk(
  'priceLists/createPriceList',
  async (newPriceList, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${priceListUrl}/store`, newPriceList, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for updating priceLists
export const updatePriceList = createAsyncThunk('priceLists/updatePriceList', async (priceLists, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    let priceListsData = state.priceList.priceList;
    const response = await axiosInstance.post(`${priceListUrl}/update/${priceListsData.id}`, priceLists, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for deleting priceLists
export const deletePriceList = createAsyncThunk('priceLists/deletePriceList', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.delete(`${priceListUrl}/destroy/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const PriceListSlice = createSlice({
  name: 'priceList',
  initialState: {
    loading: false,
    isModalLoading: false,
    priceLists: [],
    priceListData: [],
    priceList: null,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_PRICE_LIST,
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch priceLists
      .addCase(fetchPriceLists.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchPriceLists.fulfilled, (state, action) => {
        state.loading = false;
        state.priceLists = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchPriceLists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch pricelist data
      .addCase(fetchPriceListData.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchPriceListData.fulfilled, (state, action) => {
        state.loading = false;
        state.priceListData = action.payload.payload;
      })
      .addCase(fetchPriceListData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create priceList
      .addCase(createPriceList.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(createPriceList.fulfilled, (state, action) => {
        state.isModalLoading = false;
        state.priceLists.push(action.payload.payload);
      })
      .addCase(createPriceList.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // Fetch priceList
      .addCase(fetchPriceList.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchPriceList.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.priceList = data;
        state.isModalLoading = false;
      })
      .addCase(fetchPriceList.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // update priceList
      .addCase(updatePriceList.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updatePriceList.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.priceLists.findIndex(priceList => priceList.id === data.id);
        if (index !== -1) {
          state.priceLists[index] = data;
        }
        state.priceList = data;
        state.isModalLoading = false;
      })
      .addCase(updatePriceList.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // delete priceList
      .addCase(deletePriceList.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(deletePriceList.fulfilled, (state, action) => {
        state.priceList = null;
        state.isModalLoading = false;
      })
      .addCase(deletePriceList.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      });
  }
})


export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = PriceListSlice.actions;

export default PriceListSlice.reducer;