import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';

const questionUrl = 'api/admin/question';

export const fetchAdditionalInfos = createAsyncThunk(
  'additionalInfos/fetchAdditionalInfos',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${questionUrl}/index/additional-info/${id}`, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Async thunk for fetching AdditionalInfo
export const fetchAdditionalInfo = createAsyncThunk('additionalInfos/fetchAdditionalInfo', async (id, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${questionUrl}/show/additional-info/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue);
  }
});

export const createAdditionalInfo = createAsyncThunk(
  'additionalInfos/createAdditionalInfo',
  async (newAdditionalInfo, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${questionUrl}/store/additional-info`, newAdditionalInfo, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Async thunk for updating AdditionalInfo
export const updateAdditionalInfo = createAsyncThunk('additionalInfos/updateAdditionalInfo', async (additionalInfo, { getState, rejectWithValue }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post(`${questionUrl}/update/additional-info/${additionalInfo.id}`, additionalInfo, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue);
  }
});

const AdditionalInfoSlice = createSlice({
  name: 'additionalInfo',
  initialState: {
    loading: false,
    isModalLoading: false,
    additionalInfos: [],
    additionalInfo: null,
    error: '',
    validationError: []
  },
  reducers: {
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch additional info
      .addCase(fetchAdditionalInfos.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchAdditionalInfos.fulfilled, (state, action) => {
        state.loading = false;
        state.additionalInfos = action.payload.payload.data;
      })
      .addCase(fetchAdditionalInfos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create additionaInfo
      .addCase(createAdditionalInfo.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(createAdditionalInfo.fulfilled, (state, action) => {
        state.isModalLoading = false;
        let addInfo = action.payload.payload;
        if (addInfo) {
          addInfo['step'] = 0;
          addInfo['submit'] = false;
        }
        state.additionalInfos.push(addInfo);
      })
      .addCase(createAdditionalInfo.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // Fetch additional info
      .addCase(fetchAdditionalInfo.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchAdditionalInfo.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.additionalInfo = data;
        state.isModalLoading = false;
      })
      .addCase(fetchAdditionalInfo.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // update additional info
      .addCase(updateAdditionalInfo.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updateAdditionalInfo.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.additionalInfos.findIndex(additionalInfo => additionalInfo.id === data.id);
        if (index !== -1) {
          state.additionalInfos[index] = data;
        }
        state.additionalInfo = data;
        state.isModalLoading = false;
      })
      .addCase(updateAdditionalInfo.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      });
  }
});

export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = AdditionalInfoSlice.actions;

export default AdditionalInfoSlice.reducer;
