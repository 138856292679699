import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_QUESTION } from '../../../constants/pagination';

const questionUrl = 'api/admin/question';

export const fetchQuestions = createAsyncThunk(
  'questions/fetchQuestions',
  async ({ page = 1, filter = '', category_id = '', perPage = PER_PAGE_QUESTION }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${questionUrl}/index`, {
        params: { page, per_page: perPage, search: filter, filter_by_category: category_id },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching question
export const fetchQuestion = createAsyncThunk('questions/fetchQuestion', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${questionUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const createQuestion = createAsyncThunk(
  'questions/createQuestion',
  async (newQuestion, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${questionUrl}/store`, newQuestion, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for updating question
export const updateQuestion = createAsyncThunk('questions/updateQuestion', async (question, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    let questionData = state.question.question;
    const response = await axiosInstance.post(`${questionUrl}/update/${questionData.id}`, question, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for reordering question
export const saveQuestionOrder = createAsyncThunk('questions/reorderQuestion', async ({ id, newOrder }, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${questionUrl}/reorder/${id}`, {
      params: { new_order: newOrder + 1 },
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for deleting question
export const deleteQuestion = createAsyncThunk('questions/deleteQuestion', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.delete(`${questionUrl}/destroy/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for fetching type list
export const fetchTypeList = createAsyncThunk(
  'questions/fetchTypeList',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${questionUrl}/type`, {
        headers: prepareHeaders(state, true)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const questionSlice = createSlice({
  name: 'question',
  initialState: {
    loading: false,
    isModalLoading: false,
    questions: [],
    typeListData: [],
    question: null,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    category_id: '',
    perPage: PER_PAGE_QUESTION,
    error: '',
    ValidationError: []
  },
  reducers: {
    setCategoryId: (state, action) => {
      state.category_id = action.payload;
      state.currentPage = 0;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch questions
      .addCase(fetchQuestions.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create question
      .addCase(createQuestion.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(createQuestion.fulfilled, (state, action) => {
        state.isModalLoading = false;
        state.questions.push(action.payload.payload);
      })
      .addCase(createQuestion.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // Fetch question
      .addCase(fetchQuestion.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchQuestion.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.question = data;
        state.isModalLoading = false;
      })
      .addCase(fetchQuestion.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // update question
      .addCase(updateQuestion.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.questions.findIndex(question => question.id === data.id);
        if (index !== -1) {
          state.questions[index] = data;
        }
        state.question = data;
        state.isModalLoading = false;
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // reorder question
      .addCase(saveQuestionOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveQuestionOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.questions = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(saveQuestionOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // delete question
      .addCase(deleteQuestion.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.question = null;
        state.isModalLoading = false;
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // fetch types list
      .addCase(fetchTypeList.pending, (state) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(fetchTypeList.fulfilled, (state, action) => {
        state.loading = false;
        state.typeListData = action.payload.payload;
      })
      .addCase(fetchTypeList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setCategoryId, setFilter, setPage, setPerPage, clearError, clearValidationError } = questionSlice.actions;

export default questionSlice.reducer;