
const DEFAULT_PER_PAGE_OPTIONS = [5, 10, 20];
const PER_PAGE_AUDIT_LOG = 10;
const PER_PAGE_CATEGORY = 10;
const PER_PAGE_CUSTOMER = 20;
const PER_PAGE_CUSTOMER_SKU = 20;
const PER_PAGE_COUPON = 10;
const PER_PAGE_GROUP = 10;
const PER_PAGE_PRICE_LIST = 10;
const PER_PAGE_QUESTION = 10;
const PER_PAGE_USER = 10;
const PER_PAGE_SKU = 10;
const PER_PAGE_NOTIFICATION = 60;
const TOP_BAR_NOTIFICATION = 5;

export { DEFAULT_PER_PAGE_OPTIONS, PER_PAGE_AUDIT_LOG, PER_PAGE_CATEGORY, PER_PAGE_CUSTOMER, PER_PAGE_CUSTOMER_SKU, PER_PAGE_COUPON, PER_PAGE_GROUP, PER_PAGE_PRICE_LIST, PER_PAGE_QUESTION, PER_PAGE_USER, PER_PAGE_SKU, PER_PAGE_NOTIFICATION, TOP_BAR_NOTIFICATION };

