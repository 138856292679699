import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_GROUP } from '../../../constants/pagination';

const groupUrl = 'api/admin/question-group';

export const fetchGroups = createAsyncThunk(
  'groups/fetchGroups',
  async ({ page = 1, filter = '', perPage = PER_PAGE_GROUP }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${groupUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchGroupsList = createAsyncThunk(
  'groups/fetchGroupsList',
  async ({ search = '' }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      let params = [];
      if (search !== '') {
        params['search'] = search;
      }
      const response = await axiosInstance.get(`${groupUrl}/list`, {
        params,
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching group
export const fetchGroup = createAsyncThunk('groups/fetchGroup', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${groupUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async (newGroup, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${groupUrl}/store`, newGroup, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for updating group
export const updateGroup = createAsyncThunk('groups/updateGroup', async (group, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    let groupData = state.group.group;
    const response = await axiosInstance.post(`${groupUrl}/update/${groupData.id}`, group, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for reordering group
export const saveGroupOrder = createAsyncThunk('groups/reorderGroup', async ({ id, newOrder }, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${groupUrl}/reorder/${id}`, {
      params: { new_order: newOrder + 1 },
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for deleting group
export const deleteGroup = createAsyncThunk('groups/deleteGroup', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.delete(`${groupUrl}/destroy/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    loading: false,
    isModalLoading: false,
    groups: [],
    groupListData: [],
    group: null,
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_GROUP,
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch groups
      .addCase(fetchGroups.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch groups list
      .addCase(fetchGroupsList.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchGroupsList.fulfilled, (state, action) => {
        state.loading = false;
        state.groupListData = action.payload.payload;
      })
      .addCase(fetchGroupsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create group
      .addCase(createGroup.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.isModalLoading = false;
        state.groups.push(action.payload.payload);
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // Fetch group
      .addCase(fetchGroup.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchGroup.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.group = data;
        state.isModalLoading = false;
      })
      .addCase(fetchGroup.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // update group
      .addCase(updateGroup.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.groups.findIndex(group => group.id === data.id);
        if (index !== -1) {
          state.groups[index] = data;
        }
        state.group = data;
        state.isModalLoading = false;
      })
      .addCase(updateGroup.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // reorder group
      .addCase(saveGroupOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveGroupOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(saveGroupOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // delete group
      .addCase(deleteGroup.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.group = null;
        state.isModalLoading = false;
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      });
  }
});

export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = groupSlice.actions;

export default groupSlice.reducer;
