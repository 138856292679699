export const AVAILABLE = 'Available';
export const IN_PROGRESS = 'In Progress';
export const REQUESTED = 'Requested';
export const IN_REVIEW = 'In Review';
export const ADDITIONAL_INFO = 'Additional Info';
export const FORWARDED = 'Forwarded';
export const CANCELED = 'Canceled';
export const NOT_RESPONDED = 'Not Responded';
export const ADDITIONAL_INFO_SUBMITTED = 'Additional Info Submitted';
export const FINALIZED = 'Finalized';

export const SKU_STATUS = [IN_PROGRESS, REQUESTED, IN_REVIEW, ADDITIONAL_INFO, FORWARDED, CANCELED, NOT_RESPONDED, ADDITIONAL_INFO_SUBMITTED, FINALIZED];

// review status
export const REVIEW_STATUS_OPEN = "Open";
export const REVIEW_STATUS_PICKED = "Picked";
export const REVIEW_STATUS_COMPLETED = "Completed";
