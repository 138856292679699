import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';

const settingUrl = 'api/admin/setting';

// Async thunk for fetching setting
export const fetchSetting = createAsyncThunk('settings/fetchSetting', async (_, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${settingUrl}/index`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for updating setting
export const saveSetting = createAsyncThunk('settings/saveSetting', async (setting, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post(`${settingUrl}/store`, setting, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const SettingSlice = createSlice({
  name: 'setting',
  initialState: {
    loading: false,
    setting: null,
    error: '',
    validationError: []
  },
  reducers: {
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch setting
      .addCase(fetchSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSetting.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.setting = data;
        state.loading = false;
      })
      .addCase(fetchSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // save setting
      .addCase(saveSetting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSetting.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.setting = data;
        state.loading = false;
      })
      .addCase(saveSetting.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload !== 'string') {
          state.ValidationError = action.payload.errors
        }
      });
  }
});

export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = SettingSlice.actions;

export default SettingSlice.reducer;
