import { configureStore } from "@reduxjs/toolkit";

import AuthSlice from "../modules/auth/slice/AuthSlice";
import AdditionalInfoSlice from "../modules/additionalInfo/slice/AdditionalInfoSlice";
import AuditLogSlice from "../modules/auditLog/slice/AuditLogSlice";
import CategorySlice from "../modules/category/slice/CategorySlice";
import CustomerSlice from "../modules/customer/slice/CustomerSlice";
import DiscountCouponSlice from "../modules/discountCoupon/slice/DiscountCouponSlice";
import GroupSlice from "../modules/group/slice/GroupSlice";
import HeaderSlice from "../modules/common/slice/HeaderSlice";
import LanguageSlice from "../modules/common/slice/LanguageSlice";
import ModalSlice from "../modules/common/slice/ModalSlice";
import NotificationSlice from "../modules/notification/slice/NotificationSlice";
import PriceListSlice from "../modules/priceList/slice/PriceListSlice";
import QuestionSlice from "../modules/question/slice/QuestionSlice";
import SalesListSlice from "../modules/salesHistory/slice/SalesListSlice";
import SettingSlice from "../modules/setting/slice/SettingSlice";
import SkuSlice from "../modules/sku/slice/SkuSlice";
import UserSlice from "../modules/user/slice/UserSlice";

const combinedReducer = {
  auth: AuthSlice,
  additionalInfo: AdditionalInfoSlice,
  auditLog: AuditLogSlice,
  category: CategorySlice,
  customer: CustomerSlice,
  coupon: DiscountCouponSlice,
  group: GroupSlice,
  header: HeaderSlice,
  language: LanguageSlice,
  modal: ModalSlice,
  notification: NotificationSlice,
  priceList: PriceListSlice,
  question: QuestionSlice,
  sales: SalesListSlice,
  setting: SettingSlice,
  sku: SkuSlice,
  user: UserSlice,
};

export default configureStore({
  reducer: combinedReducer,
});
