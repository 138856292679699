import { clearUserState, removeUserData } from "../../auth/slice/AuthSlice";

export const handleApiError = (error, rejectWithValue, dispatch, removeUserDataIfUnauthenticated = true) => {
  if (error.response) {
    const { status, data } = error.response;

    switch (status) {
      case 401:
        if (removeUserDataIfUnauthenticated) {
          removeUserData();
          dispatch(clearUserState());
        }
        return rejectWithValue(data);
      case 422:
        return rejectWithValue(data);
      default:
        return rejectWithValue(error.response?.data?.message ?? error.response.statusText);
    }
  } else {
    return rejectWithValue(error.message);
  }
};
