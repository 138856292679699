// AuthSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { handleApiError } from "../../common/utils/ErrorHandler";

// initialize userToken from local storage
const user = localStorage.getItem("user") !== 'undefined'
  ? JSON.parse(localStorage.getItem("user"))
  : null;
const userToken = localStorage.getItem("userToken") !== 'undefined'
  ? localStorage.getItem("userToken")
  : null;


export const removeUserData = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('user');
};


export const prepareHeaders = (state, addToken = true, options = {}) => {
  let headers = {};
  if (state?.language?.language) {
    headers['locale'] = state.language.language;
  }
  if (addToken && state?.auth?.userToken) {
    headers['Authorization'] = `Bearer ${state.auth.userToken}`;
  }
  if (typeof options === 'object' && !Array.isArray(options) && options !== null) {
    headers = { ...options, ...headers };
  }
  return headers
}

// Async thunk for login
export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        "/api/admin/auth/login",
        credentials,
        {
          headers: prepareHeaders(state, false),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch, false);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();

      const response = await axiosInstance.post(
        "/api/admin/auth/logout",
        {},
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);


// Async thunk for fetching profile
export const fetchProfile = createAsyncThunk('auth/fetchProfile', async (_, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get('/api/admin/auth/user', {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});


// Async thunk for updating profile
export const updateProfile = createAsyncThunk('auth/updateProfile', async (profileData, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.post('/api/admin/update-profile', profileData, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const updateProfileImage = createAsyncThunk(
  'auth/updateProfileImage',
  async (imageData, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const formData = new FormData();
      formData.append('profile_image', imageData);

      const response = await axiosInstance.post('/api/admin/profile-picture', formData, {
        headers: prepareHeaders(state, true, {
          'Content-Type': 'multipart/form-data',
        }),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (passwordData, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post('/api/admin/change-password', passwordData, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: userToken ? true : false,
    userToken,
    user,
    role: user?.role,
    isSuperAdmin: user?.is_super_admin,
    loading: false,
    error: null,
    validationError: []
  },
  reducers: {
    clearUserState: (state) => {
      state.isAuthenticated = false;
      state.userToken = null;
      state.user = null;
      state.role = null;
      state.isSuperAdmin = false;
    }

  },
  extraReducers: (builder) => {
    builder
      // login case
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        let data = action.payload.payload;
        // store user's token in local storage
        localStorage.setItem("userToken", data.access_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        state.user = data.user;
        state.userToken = data.access_token;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'object') {
          state.error = action.payload.message;
          state.validationError = action.payload.errors;
        }
      })
      // logout case
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("user");
        state.isAuthenticated = false;
        state.user = null;
        state.role = null;
        state.userToken = null;
        state.loading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.userToken = null;
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch profile cases
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        let data = action?.payload?.payload;
        // store user's data in local storage
        localStorage.setItem("user", JSON.stringify(data));
        state.user = data;
        state.role = data?.role;
        state.isSuperAdmin = data?.is_super_admin;
        state.loading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update profile cases
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action?.payload?.payload;
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'object') {
          state.validationError = action.payload.errors;
        }
      })
      // Change password cases
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'object') {
          state.validationError = action.payload.errors;
        }
      })
      // Update profile image cases
      .addCase(updateProfileImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.user = action.payload.payload;
        state.loading = false;
      })
      .addCase(updateProfileImage.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'object') {
          state.validationError = action.payload.errors;
        }
      });
  },
});

export const { clearUserState } = AuthSlice.actions;

export default AuthSlice.reducer;

